import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import LoginScreen from '../components/login/LoginScreen';
import PasswordRecovery from '../components/login/PasswordRecovery';
import SetNewPassword from '../components/login/SetNewPassword';
import LaunchMonitoring from '../components/monitoring/LaunchMonitoring';

export const AuthOutlet = ({ isAuthenticated }) => {
    return !isAuthenticated ? (
        <Routes>
            <Route path="login" element={<LoginScreen />} />
            <Route path="email-password-recovery" element={<PasswordRecovery />} />
            <Route path="password-recovery/:db" element={<SetNewPassword />} />
            <Route path="demo" element={<LaunchMonitoring />} />

            <Route path="*" element={<Navigate replace to="login" />} />
        </Routes>
    ) : (
        <Navigate replace to="/" />
    )
}
