import React, { useState } from "react";

const FiltersMenu = ({
    isFilterMenuVisible,
    enterprises,
    status,
    services,
    launchListBackup,
    toggleFilterMenu,
    menuFilterInputs,
    setMenuFilterInputs,
    handleFilters,
    setLaunchList,
    setFilteredData,
    setPage,
}) => {
    // TODO: state
    // string
    const [launchStartDate, setLaunchStartDate] = useState("");
    const [launchEndDate, setLaunchEndDate] = useState("");
    const [closeStartDate, setCloseStartDate] = useState("");
    const [closeEndDate, setCloseEndDate] = useState("");
    const [processedStartDate, setProcessedStartDate] = useState("");
    const [processedEndDate, setProcessedEndDate] = useState("");
    // number
    const [enterpriseValue, setEnterpriseValue] = useState(0);
    const [statusValue, setStatusValue] = useState(0);
    const [serviceValue, setServiceValue] = useState(0);

    const {
        launchEnd,
        closedEnd,
        processedEnd,
    } = menuFilterInputs;

    // Obtener la fecha actual en formato YYYY-MM-DD
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    };

    const cleanInputs = () => {
        setEnterpriseValue("");
        setStatusValue("");
        setServiceValue("");
        setLaunchStartDate("");
        setLaunchEndDate("");
        setCloseStartDate("");
        setCloseEndDate("");
        setProcessedStartDate("");
        setProcessedEndDate("");

        setLaunchList(launchListBackup);
        setFilteredData(launchListBackup);
        setPage(1); // Reinicia la página a 1 después de filtrar
    }

    const handleInputDate = (value, inputID) => {
        switch (inputID) {
            case 'launchStart':
                setLaunchStartDate(value);
                // Si el segundo input está vacío, lo inicializamos con la fecha seleccionada en el primer input
                if (!processedEndDate) {
                    setLaunchEndDate(value);
                }
                break;
            case 'launchEnd':
                setLaunchEndDate(value);
                break;
            case 'closedStart':
                setCloseStartDate(value);
                // Si el segundo input está vacío, lo inicializamos con la fecha seleccionada en el primer input
                if (!processedEndDate) {
                    setCloseEndDate(value);
                }
                break;
            case 'closedEnd':
                setCloseEndDate(value);
                break;
            case 'processedStart':
                setProcessedStartDate(value);
                // Si el segundo input está vacío, lo inicializamos con la fecha seleccionada en el primer input
                if (!processedEndDate) {
                    setProcessedEndDate(value);
                }
                break;
            case 'processedEnd':
                setProcessedEndDate(value);
                break;
            default:
                break;
        }
        if (launchEnd || closedEnd || processedEnd) { // Limpiar el border rojo
            setMenuFilterInputs({
                launchEnd: false,
                closedEnd: false,
                processedEnd: false,
            });
        }
    }

    return (
        <div className={`menu-filter ${isFilterMenuVisible ? "visible" : ""}`}>
            <div
                className="col-12 h-100"
                style={{ overflowY: 'auto' }}
            >
                <div className='col-11 mx-auto d-flex justify-content-end'>
                    <button
                        type="button"
                        className="btn"
                        onClick={toggleFilterMenu}
                    ><i className="bi bi-x-lg"></i></button>
                </div>
                <div className='col-11 mx-auto border-bottom'>
                    <p className='text-center fs-3 m-0'>Filtros</p>
                </div>
                <div className="col-11 mx-auto mb-3 d-flex justify-content-between pt-3">
                    <button
                        type="button"
                        className="btn btn-cancel-style2"
                        onClick={() => cleanInputs()}
                    >Limpiar</button>
                    <button
                        type="button"
                        className="btn btn-save-style2"
                        onClick={() => handleFilters(enterpriseValue, statusValue, serviceValue, launchStartDate, launchEndDate, closeStartDate, closeEndDate, processedStartDate, processedEndDate)}
                    >Aplicar</button>
                </div>
                <div className='col-11 mx-auto'>
                    <div className="row mb-3">
                        <div className="col">
                            <div className='form-group'>
                                <label htmlFor='enterprise'>Empresa</label>
                                <select
                                    className='form-control form-control-sm'
                                    id='enterprise'
                                    value={enterpriseValue}
                                    onChange={(e) => setEnterpriseValue(parseInt(e.target.value))}
                                >
                                    <option value={0}>Seleccionar</option>
                                    {enterprises.map(enterprise => (
                                        <option
                                            key={`enterprise-${enterprise.id_enterprise}`}
                                            value={enterprise.id_enterprise}
                                        >{enterprise.enterprise_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className='form-group'>
                                <label htmlFor='status'>Status</label>
                                <select
                                    className='form-control form-control-sm'
                                    id='status'
                                    value={statusValue}
                                    onChange={(e) => setStatusValue(parseInt(e.target.value))}
                                >
                                    <option value={0}>Seleccionar</option>
                                    {status.map(statu => (
                                        <option
                                            key={`status-${statu.status}`}
                                            value={statu.status}
                                        >{statu.status_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='survey'>Servicio</label>
                        <select
                            className='form-control form-control-sm'
                            id='survey'
                            value={serviceValue}
                            onChange={(e) => setServiceValue(parseInt(e.target.value))}
                        >
                            <option value={0}>Seleccionar</option>
                            {services.map(service => (
                                <option
                                    key={`service-${service.id_category}`}
                                    value={service.id_category}
                                >{service.service}</option>
                            ))}
                        </select>
                    </div>
                    <label htmlFor='launch_date'>Fecha de lanzamiento</label>
                    <div className='input-group input-group-sm'>
                        <input
                            type='date'
                            className='form-control'
                            id='launch_date'
                            value={launchStartDate}
                            onChange={(e) => handleInputDate(e.target.value, 'launchStart')}
                            max={getCurrentDate()} // Tope máximo: fecha actual
                            min="2010-01-01" // Tope mínimo: fecha mínima permitida 
                        />
                        <input
                            type='date'
                            className={`form-control ${launchEnd && 'border-danger'}`}
                            id='launch_date_end'
                            value={launchEndDate}
                            onChange={(e) => handleInputDate(e.target.value, 'launchEnd')}
                            max={getCurrentDate()} // Tope máximo: fecha actual
                            min={launchStartDate || "2010-01-01"} // Tope mínimo: fecha seleccionada en el primer input o fecha mínima permitida
                            disabled={!launchStartDate} // Deshabilitar hasta que el primer input tenga un valor 
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col d-flex justify-content-center">
                            <span>Inicio</span>
                        </div>
                        <div className="col d-flex justify-content-center">
                            <span>Fin</span>
                        </div>
                    </div>
                    <label htmlFor='close_date'>Fecha de cierre</label>
                    <div className='input-group input-group-sm'>
                        <input
                            type='date'
                            className='form-control'
                            id='close_date'
                            value={closeStartDate}
                            onChange={(e) => handleInputDate(e.target.value, 'closedStart')}
                            max={getCurrentDate()} // Tope máximo: fecha actual
                            min="2010-01-01" // Tope mínimo: fecha mínima permitida 
                        />
                        <input
                            type='date'
                            className={`form-control ${closedEnd && 'border-danger'}`}
                            id='close_date'
                            value={closeEndDate}
                            onChange={(e) => handleInputDate(e.target.value, 'closedEnd')}
                            max={getCurrentDate()} // Tope máximo: fecha actual
                            min={closeStartDate || "2010-01-01"} // Tope mínimo: fecha seleccionada en el primer input o fecha mínima permitida
                            disabled={!closeStartDate} // Deshabilitar hasta que el primer input tenga un valor 
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col d-flex justify-content-center">
                            <span>Inicio</span>
                        </div>
                        <div className="col d-flex justify-content-center">
                            <span>Fin</span>
                        </div>
                    </div>
                    <label htmlFor="processed_date">Fecha de procesamiento</label>
                    <div className='input-group input-group-sm'>
                        <input
                            type='date'
                            className='form-control'
                            id='processed_date'
                            value={processedStartDate}
                            onChange={(e) => handleInputDate(e.target.value, 'processedStart')}
                            max={getCurrentDate()} // Tope máximo: fecha actual
                            min="2010-01-01" // Tope mínimo: fecha mínima permitida 
                        />
                        <input
                            type='date'
                            className={`form-control ${processedEnd && 'border-danger'}`}
                            id='processed_date_end'
                            value={processedEndDate}
                            onChange={(e) => handleInputDate(e.target.value, 'processedEnd')}
                            max={getCurrentDate()} // Tope máximo: fecha actual
                            min={processedStartDate || "2010-01-01"} // Tope mínimo: fecha seleccionada en el primer input o fecha mínima permitida
                            disabled={!processedStartDate} // Deshabilitar hasta que el primer input tenga un valor 
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col d-flex justify-content-center">
                            <span>Inicio</span>
                        </div>
                        <div className="col d-flex justify-content-center">
                            <span>Fin</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FiltersMenu;