import axios from 'axios';

/**
 * Obtiene un listado de los lanzamientos activos con datos de BD y ETL
 * @returns 
 */
export const getMonitorReport = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: `${global.base_url_admin}monitorLaunch/getMonitorReport`,
            headers: {
                Authorization: global.tokenAuth,
            },
        });
        let response = {
            data: {},
            message: '',
            validation: false,
        };
        switch (result.data.code) {
            case 1:
                response = {
                    data: result.data,
                    message: '',
                    validation: true,
                };
                break;
            case 2:
                response = {
                    data: {},
                    message: 'No hay lanzamientos activos por mostrar.',
                    validation: false,
                };
                break;
            default:
                response = {
                    data: {},
                    message: 'No se pudo obtener la información. Si el problema persiste contacte al equipo de soporte.',
                    validation: false,
                };
                break;
        }
        return response;
    } catch (error) {
        console.log(error);
    }
};