import React, {
    useState,
    useEffect,
} from 'react';
// TODO: API calls
import { getMonitorReport } from '../../models/monitorLaunch';
// TODO: Componentes
import ColumnMenu from './ColumnMenu';
import LaunchTable from './LaunchTable';
import FiltersMenu from './FiltersMenu';
// TODO: Dependecies
// import * as toastr from 'toastr';
import { handleDateTextFormat } from '../../helpers/genericFunc';

const LaunchMonitoring = () => {
    // TODO: state
    // array
    const [launchListBackup, setLaunchListBackup] = useState([]); // Datos filtrados
    const [launchList, setLaunchList] = useState([]); // Datos filtrados
    const [filteredData, setFilteredData] = useState([]); // Datos filtrados
    const [enterprises, setEnterprises] = useState([]);
    const [status, setStatus] = useState([]);
    const [services, setServices] = useState([]);
    // number
    const [page, setPage] = useState(1); // Página actual
    const [sizePerPage, setSizePerPage] = useState(10); // Tamaño de la página
    // boolean
    const [showTable, setShowTable] = useState(false); // Datos filtrados
    const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
    const [isColumnMenuVisible, setIsColumnMenuVisible] = useState(false);
    // object
    const [tableColumns, setTableColumns] = useState({
        hidden_1: true,
        hidden_2: true,
        hidden_3: true,
        hidden_4: true,
        hidden_5: true,
        hidden_6: true,
        hidden_7: true,
        hidden_8: true,
        hidden_9: false,
        hidden_10: false,
        hidden_11: false,
        hidden_12: true,
    });
    const [menuFilterInputs, setMenuFilterInputs] = useState({
        launchEnd: false,
        closedEnd: false,
        processedEnd: false,
    });
    // string
    const [searcher, setSearcher] = useState("");

    useEffect(() => {
        getMonitorReport().then(response => { // API para obtener la información de la tabla
            /**
             * Función para generar un id
             * @returns 
             */
            const generateUniqueId = () => {
                const timestamp = Date.now(); // Obtiene la marca de tiempo actual en milisegundos
                const random = Math.random().toString(36).substring(2, 9); // Genera un string aleatorio
                return `${timestamp}-${random}`; // Combina la marca de tiempo y el string aleatorio
            };
            const setIdentifier = response.data.monitorLaunchList.map(launch => ({ ...launch, id: generateUniqueId() })); // Se agregar un id a cada registro para control de la tabla
            setLaunchList(setIdentifier);
            setFilteredData(setIdentifier);
            setLaunchListBackup(setIdentifier);
            // Función para eliminar duplicados basados en una clave específica
            const removeDuplicates = (array, key) => {
                const seen = {}; // Objeto temporal para rastrear duplicados
                return array.filter((item) => {
                    const value = item[key];
                    if (!seen[value]) {
                        seen[value] = true; // Marcar como visto
                        return true;
                    }
                    return false;
                });
            };
            // Crear array de empresas sin duplicados
            const enterprisesArray = removeDuplicates(response.data.monitorLaunchList, "id_enterprise").map(
                (item) => ({
                    id_enterprise: item.id_enterprise,
                    enterprise_name: item.enterprise_name,
                })
            );
            enterprisesArray.sort((a, b) => {
                const nameA = a.enterprise_name.toLowerCase();
                const nameB = b.enterprise_name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
            // Crear array de status sin duplicados
            const statusArray = removeDuplicates(response.data.monitorLaunchList, "status").map((item) => ({
                status: item.status,
                status_name: item.status_name,
            }));
            // Crear array de services sin duplicados
            const servicesArray = removeDuplicates(response.data.monitorLaunchList, "id_category").map((item) => ({
                id_category: item.id_category,
                service: item.service,
            }));
            //console.log(servicesArray)
            // console.log("Empresas:", enterprisesArray);
            setEnterprises(enterprisesArray)
            // console.log("Status:", statusArray);
            setStatus(statusArray)
            // console.log("Servicios:", servicesArray);
            setServices(servicesArray);
            setShowTable(true);
        });
    }, []);

    function statusFormatter(cell, row) {
        let component = <span>{cell}</span>;
        switch (row.status) {
            case 1:
                component = <span className='status-body status-blue'>{cell}</span>
                break;
            case 2:
                component = <span className='status-body status-yellow'>{cell}</span>
                break;
            case 3:
                component = <span className='status-body status-gray'>{cell}</span>
                break;
            case 4:
                component = <span className='status-body status-orange'>{cell}</span>
                break;
            case 5:
                component = <span className='status-body status-green'>{cell}</span>
                break;
            default:
                break;
        }
        return component;
    };
    function dateFormatter(cell, row) {
        if (cell !== "" && cell !== undefined) {
            const textDate = handleDateTextFormat(cell);
            return textDate
        } else {
            return cell
        }
    };

    const columns = [
        { dataField: "id", text: "ID Registro", hidden: true },
        { dataField: "id_enterprise", text: "ID Empresa", hidden: true },
        { dataField: "enterprise_name", text: "Empresa", headerAlign: 'center', headerStyle: { width: '275px' }, style: { width: '275px' } },
        { dataField: "id_group", text: "ID Grupo", hidden: true },

        { dataField: "status", text: "ID Status", hidden: true },
        { dataField: "status_name", text: "Status", headerAlign: 'center', headerStyle: { width: '150px' }, style: { width: '150px' }, align: 'center', formatter: statusFormatter },

        { dataField: "launch_register_date", text: "Registro de Lanzamiento", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', formatter: dateFormatter, hidden: tableColumns.hidden_12 },
        { dataField: "launch_date", text: "Fecha de Lanzamiento", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', formatter: dateFormatter, hidden: tableColumns.hidden_1 },
        { dataField: "close_date", text: "Fecha de Cierre", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', formatter: dateFormatter },

        { dataField: "launch_name", text: "Lanzamiento", headerAlign: 'center', headerStyle: { width: '275px' }, style: { width: '275px' }, hidden: tableColumns.hidden_2 },
        { dataField: "id_category", text: "ID Category", hidden: true },
        { dataField: "id_subcategory", text: "ID Subcategory", hidden: true },
        { dataField: "type_launch", text: "Tipo de Lanzamiento", hidden: true },

        { dataField: "service", text: "Servicio", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_3 },
        { dataField: "id_survey", text: "ID Survey", hidden: true },
        { dataField: "survey", text: "Evaluación", headerAlign: 'center', headerStyle: { width: '150px' }, style: { width: '150px' } },
        { dataField: "collabs_launch", text: "Colaboradores Asigandos", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center' },
        { dataField: "collabs_temp_left_launch", text: "Bajas Temporales", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_4 },
        { dataField: "collabs_removed_launch", text: "Bajas Definitivas", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_5 },
        { dataField: "collabs_attended_complete_launch", text: "Evaluaciones Completas", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_6 },
        { dataField: "collabs_attended_incomplete_launch", text: "Evaluaciones Incompletas", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_7 },
        { dataField: "collabs_not_attended_launch", text: "Evaluaciones Sin Responder", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_8 },

        { dataField: "upload_date_etl", text: "Fecha de Procesamiento", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', formatter: dateFormatter, hidden: tableColumns.hidden_9 },
        { dataField: "processed_answers_etl", text: "Respuestas Procesadas", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_10 },
        { dataField: "variation_etl", text: "Variación", headerAlign: 'center', headerStyle: { width: '125px' }, style: { width: '125px' }, align: 'center', hidden: tableColumns.hidden_11 },
    ];

    const options = {
        custom: true,
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: "Primera",
        prePageText: "Anterior",
        nextPageText: "Siguiente",
        lastPageText: "Última",
        nextPageTitle: "Primera página",
        prePageTitle: "Previa página",
        firstPageTitle: "Primera página",
        lastPageTitle: "Última página",
        showTotal: true,
        totalSize: launchList.length,
        onPageChange: (page) => {
            setPage(page);
        },
        page: page, // Establece la página actual
    };

    // Función para manejar el filtrado remoto
    const handleFilterChange = (filteredData) => {
        setFilteredData(filteredData); // Actualiza los datos filtrados
        setPage(1); // Reinicia la página a 1 después de filtrar
    };

    // Función para manejar cambios en la paginación
    const handleTableChange = (type, { page, sizePerPage }) => {
        setPage(page); // Actualiza la página actual
        setSizePerPage(sizePerPage); // Actualiza el tamaño de la página
    };

    const rowClasses = (row, rowIndex) => {
        let classes = null;
        if (rowIndex % 2 !== 0) {
            classes = 'table-row-model-1 row-style-1';
        } else {
            classes = 'table-row-model-1 row-style-2';
        }
        return classes;
    };

    const toggleColumnMenu = () => {
        setIsColumnMenuVisible(!isColumnMenuVisible);
    };

    const toggleFilterMenu = () => {
        setIsFilterMenuVisible(!isFilterMenuVisible);
    };

    /**
     * Mustra u oculta las columnas de la tabla
     * @param {*} column 
     */
    const handleShowColumns = (column) => {
        const tableColumnsBackup = tableColumns;
        let newTableColumns = {};
        for (let index = 0; index < Object.keys(tableColumnsBackup).length; index++) {
            newTableColumns = {
                ...tableColumnsBackup,
                [`hidden_${column}`]: !tableColumnsBackup[`hidden_${column}`]
            };
        }
        setTableColumns(newTableColumns);
    };

    const handleFilters = (
        enterprise,
        status,
        services,
        launch_start_date,
        launch_end_date,
        close_start_date,
        close_end_date,
        processed_start_date,
        processed_end_date,
    ) => {
        // const inicio = performance.now(); // Obtiene el tiempo inicial
        const tableList = launchListBackup; // Array con los datos originales
        // Flags
        const enterpriseFlag = enterprise === 0 || enterprise === "" ? false : true;
        const statusFlag = status === 0 || status === "" ? false : true;
        const servicesFlag = services === 0 || services === "" ? false : true;
        const startLaunchDateFlag = launch_start_date === "" ? false : true;
        const endLaunchDateFlag = launch_end_date === "" ? false : true;
        const startCloseDateFlag = close_start_date === "" ? false : true;
        const endCloseDateFlag = close_end_date === "" ? false : true;
        const startProcessedDateFlag = processed_start_date === "" ? false : true;
        const endProcessedDateFlag = processed_end_date === "" ? false : true;
        if (!enterpriseFlag && !statusFlag && !servicesFlag && !startLaunchDateFlag && !endLaunchDateFlag && !startCloseDateFlag && !endCloseDateFlag && !startProcessedDateFlag && !endProcessedDateFlag) {
            return;
        }
        /**
         * Función que devuelve un array de acuerdo a dos fechas
         * @param {string} start yyyy-mm-dd
         * @param {string} end yyyy-mm-dd
         * @param {array} array []
         * @param {string} key ej. 'launch_date'
         * @returns 
         */
        const getRangeDate = (start, end, array, key) => {
            let getRangLaunchDate = [];
            if (start > end) { // Validar si la fecha de inicio es más reciente que la de fin
                getRangLaunchDate = array.filter(item => {
                    return item[key] >= end && item[key] <= start; // Acomoda las fechas para obtener los datos correctos
                });
            } else if (start < end) {
                getRangLaunchDate = array.filter(item => {
                    return item[key] >= start && item[key] <= end;
                });
            } else {
                getRangLaunchDate = array.filter(item => {
                    return item[key] >= start && item[key] <= end;
                });
            }
            return getRangLaunchDate;
        }
        // Validaciones
        if ((startLaunchDateFlag && !endLaunchDateFlag) || (!startLaunchDateFlag && endLaunchDateFlag)) {
            setMenuFilterInputs({
                ...menuFilterInputs,
                launchEnd: true,
            });
            //toastr.info("Ambas fechas se tienen que llenar en Fecha de lanzamiento.", "Ooops!");
            return;
        }
        if ((startCloseDateFlag && !endCloseDateFlag) || (!startCloseDateFlag && endCloseDateFlag)) {
            setMenuFilterInputs({
                ...menuFilterInputs,
                closedEnd: true,
            });
            // toastr.info("Ambas fechas se tienen que llenar en Fecha de cierre.", "Ooops!");
            return;
        }
        if ((startProcessedDateFlag && !endProcessedDateFlag) || (!startProcessedDateFlag && endProcessedDateFlag)) {
            setMenuFilterInputs({
                ...menuFilterInputs,
                processedEnd: true,
            });
            // toastr.info("Ambas fechas se tienen que llenar en Fecha de procesamiento.", "Ooops!");
            return;
        }
        // Aplicación de filtros
        let filterArray = []; // Array auxiliar para llenar de acuerdo con el filtro
        if (enterpriseFlag) {
            const getEnterpriseFilter = tableList.filter(item => item.id_enterprise === enterprise);
            filterArray = getEnterpriseFilter;
        }
        if (statusFlag) {
            if (filterArray.length === 0) {
                const getStatusFilter = tableList.filter(item => item.status === status);
                filterArray = getStatusFilter;
            } else {
                const getStatusFilter = filterArray.filter(item => item.status === status);
                filterArray = getStatusFilter;
            }
        }
        if (servicesFlag) {
            if (filterArray.length === 0) {
                const getServices = tableList.filter(item => item.id_category === services);
                filterArray = getServices;
            } else {
                const getServices = filterArray.filter(item => item.id_category === services);
                filterArray = getServices;
            }
        }
        if (startLaunchDateFlag) {
            if (filterArray.length === 0) {
                const getLaunchDate = getRangeDate(launch_start_date, launch_end_date, tableList, 'launch_date');
                filterArray = getLaunchDate;
            } else {
                const getLaunchDate = getRangeDate(launch_start_date, launch_end_date, filterArray, 'launch_date');
                filterArray = getLaunchDate;
            }
        }
        if (startCloseDateFlag) {
            if (filterArray.length === 0) {
                const getCloseDate = getRangeDate(close_start_date, close_end_date, tableList, 'closed_date');
                filterArray = getCloseDate;
            } else {
                const getCloseDate = getRangeDate(close_start_date, close_end_date, filterArray, 'closed_date');
                filterArray = getCloseDate;
            }
        }
        if (startProcessedDateFlag) {
            if (filterArray.length === 0) {
                const getProcessedDate = getRangeDate(processed_start_date, processed_end_date, tableList, 'closed_date');
                filterArray = getProcessedDate;
            } else {
                const getProcessedDate = getRangeDate(processed_start_date, processed_end_date, filterArray, 'closed_date');
                filterArray = getProcessedDate;
            }
        }
        // Actualizar el state
        setSearcher(""); // Limpia el search de la tabla
        setLaunchList(filterArray);
        setFilteredData(filterArray);
        setPage(1); // Reinicia la página a 1 después de filtrar
        // const fin = performance.now(); // Obtiene el tiempo final
        // console.log(`La función tardó ${fin - inicio} milisegundos.`);
    };

    return (
        <div className="col-12 my-5">
            <div className="row">
                <div className="col-11 mx-auto mb-3">
                    <h3>Analíticos de lanzamientos</h3>
                </div>
                <div className='col-11 mx-auto my-3'>
                    <div
                        className='card py-5 px-4'
                        style={{
                            zIndex: '1000',
                            borderRadius: '25px'
                        }}
                    >
                        {showTable && (
                            <LaunchTable
                                launchList={launchList}
                                filteredData={filteredData}
                                page={page}
                                sizePerPage={sizePerPage}
                                columns={columns}
                                options={options}
                                handleFilterChange={handleFilterChange}
                                toggleFilterMenu={toggleFilterMenu}
                                toggleColumnMenu={toggleColumnMenu}
                                handleTableChange={handleTableChange}
                                rowClasses={rowClasses}
                                searcher={searcher}
                                setSearcher={setSearcher}
                            />
                        )}
                    </div>
                    <ColumnMenu
                        isColumnMenuVisible={isColumnMenuVisible}
                        toggleColumnMenu={toggleColumnMenu}
                        tableColumns={tableColumns}
                        handleShowColumns={handleShowColumns}
                    />
                    <FiltersMenu
                        isFilterMenuVisible={isFilterMenuVisible}
                        enterprises={enterprises}
                        status={status}
                        services={services}
                        launchListBackup={launchListBackup}
                        menuFilterInputs={menuFilterInputs}
                        setMenuFilterInputs={setMenuFilterInputs}
                        toggleFilterMenu={toggleFilterMenu}
                        handleFilters={handleFilters}
                        setLaunchList={setLaunchList}
                        setFilteredData={setFilteredData}
                        setPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default LaunchMonitoring;