import React from 'react';
// TODO: Dependecies
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const LaunchTable = ({
    launchList,
    filteredData,
    page,
    sizePerPage,
    columns,
    options,
    handleFilterChange,
    toggleFilterMenu,
    toggleColumnMenu,
    handleTableChange,
    rowClasses,
    searcher,
    setSearcher,
}) => {
    const NoDataIndication = () => (
        <div className="col-12" style={{ height: '500px', alignContent: 'center' }}>
            <h1>No hay datos disponibles</h1>
        </div>
    );
    return (
        <PaginationProvider
            pagination={paginationFactory({
                ...options,
                totalSize: filteredData.length, // Usa la longitud de los datos filtrados
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <div className='table-model-1'>
                    <div className='col-12 my-1 mb-4'>
                        <div className='row'>
                            <div className='col-10'>
                                <input
                                    type="text"
                                    className='rounded-pill border border-secondary fs-05625'
                                    placeholder="Buscar (Empresa o Lanzamiento)"
                                    onChange={(e) => {
                                        const searchText = e.target.value.toLowerCase();
                                        const filtered = launchList.filter(
                                            (item) =>
                                                item.enterprise_name.toLowerCase().includes(searchText) ||
                                                item.launch_name.toLowerCase().includes(searchText)
                                        );
                                        setSearcher(e.target.value);
                                        handleFilterChange(filtered);
                                    }}
                                    style={{
                                        width: "100%",
                                        padding: "10px"
                                    }}
                                    value={searcher}
                                />
                            </div>
                            <div className='col-2 d-flex justify-content-end'>
                                <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={toggleFilterMenu}
                                    style={{ marginRight: '10px' }}
                                ><i className="bi bi-filter fs-5" /></button>
                                <button
                                    type="button"
                                    className="btn btn-outline-dark"
                                    onClick={toggleColumnMenu}
                                ><i className="bi bi-eye" /></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-12' style={{ overflowX: 'auto' }}>
                        <BootstrapTable
                            remote // Importante: Se debe dejar cuando hay componentes remotos de la tabla
                            keyField="id"
                            data={filteredData.slice(
                                (page - 1) * sizePerPage,
                                page * sizePerPage
                            )} // Aplica paginación a los datos filtrados
                            columns={columns}
                            filter={filterFactory()} // Habilita el filtrado
                            onTableChange={handleTableChange} // Manejador de cambios
                            {...paginationTableProps}
                            bordered={false}
                            headerClasses='table-header-model-1'
                            rowClasses={rowClasses}
                            noDataIndication={() => <NoDataIndication />}
                        />
                    </div>
                    <div className='col-12 mt-3 d-flex justify-content-center'>
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </div>
            )}
        </PaginationProvider>
    );
};

export default LaunchTable;