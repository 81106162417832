try {
    const host = window.location.host;
    switch (host) {
        case "localhost:3000":
            global.base_url = "https://api.development.guay.digital/v2/";
            global.base_url_admin = "https://adminapi.development.guay.digital/";
            // global.base_url = "http://localhost:8181/v2/";
            // global.base_url_admin = "http://localhost:8080/";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            break;
        case "admin.development.guay.digital":
            global.base_url = "https://api.development.guay.digital/v2/";
            global.base_url_guay = "https://development.guay.digital/loginExterno";
            global.base_url_admin = "https://adminapi.development.guay.digital/";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            break;
        case "admin.staging.guay.digital":
            global.base_url = "https://api.staging.guay.digital/v2/";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            global.base_url_admin = "https://adminapi.staging.guay.digital/";
            break;
        case "admin.somosguay.com":
            global.base_url = "https://api.guay.digital/v2/";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTM2MzQ5ODcsImlhdCI6MTYzNTk1NDk4N30.JZtCAcEfChOd3M37GS61iPrO-PFqCwsSICcBHscBS20L6Vt8-2fNoilGzenuLS5TfKHgisU7-RBteQFmur8OVQ";
            global.base_url_admin = "https://adminapi.guay.digital/";
            break;
        default:
            global.base_url = "https://api.development.guay.digital/v2/";
            global.base_url_admin = "https://adminapi.development.guay.digital/";
            // global.base_url = "http://localhost:8181/v2/";
            // global.base_url_admin = "http://localhost:8080/";
            global.tokenAuth = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmcm9udC1kZXYiLCJleHAiOjE3OTA0MzE2MjMsImlhdCI6MTYzMjc1MTYyM30.qiwXHOHO4azKpe4TAflZfxbOkymWTDAERRL4OIf2Rx4TrWx6dyoKV81zESLhyWlOb0utDjMFBf7vE8XhKzANjQ";
            break;
    }
} catch (error) {
    console.log(error);
}