import React from 'react';

const ColumnMenu = ({
    isColumnMenuVisible,
    toggleColumnMenu,
    tableColumns,
    handleShowColumns,
}) => {
    return (
        <div className={`menu ${isColumnMenuVisible ? "visible" : ""}`}>
            <div className='col-12 d-flex justify-content-end'>
                <button type="button" className="btn" onClick={toggleColumnMenu} ><i className="bi bi-x-lg"></i></button>
            </div>
            <div className='col-11 mx-auto border-bottom mb-3'>
                <p className='text-center fs-3 m-0'>Columnas Activas</p>
            </div>
            <div className='col-10 mx-auto'>
                <div className='form-check form-check-guay-pink'>
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked disabled />
                    <label className="form-check-label">Empresa</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked disabled />
                    <label className="form-check-label">Estado</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_12} onChange={() => handleShowColumns(12)} />
                    <label className="form-check-label">Registro de Lanzamiento</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_1} onChange={() => handleShowColumns(1)} />
                    <label className="form-check-label">Fecha de Lanzamiento</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked disabled />
                    <label className="form-check-label">Fecha de Cierre</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_2} onChange={() => handleShowColumns(2)} />
                    <label className="form-check-label">Lanzamiento</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_3} onChange={() => handleShowColumns(3)} />
                    <label className="form-check-label">Servicio</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked disabled />
                    <label className="form-check-label">Evaluación</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked disabled />
                    <label className="form-check-label">Colaboradores Asigandos</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_4} onChange={() => handleShowColumns(4)} />
                    <label className="form-check-label">Bajas Temporales</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_5} onChange={() => handleShowColumns(5)} />
                    <label className="form-check-label">Bajas Definitivas</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_6} onChange={() => handleShowColumns(6)} />
                    <label className="form-check-label">Evaluaciones Completas</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_7} onChange={() => handleShowColumns(7)} />
                    <label className="form-check-label">Evaluaciones Incompletas</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_8} onChange={() => handleShowColumns(8)} />
                    <label className="form-check-label">Evaluaciones Sin Responder</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_9} onChange={() => handleShowColumns(9)} />
                    <label className="form-check-label">Fecha de Procesamiento</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_10} onChange={() => handleShowColumns(10)} />
                    <label className="form-check-label">Respuestas Procesadas</label>
                </div>
                <div className="form-check form-check-guay-pink">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!tableColumns.hidden_11} onChange={() => handleShowColumns(11)} />
                    <label className="form-check-label">Variación</label>
                </div>
            </div>
        </div>
    );
};

export default ColumnMenu;